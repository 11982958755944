import "./admin-users.css";
import { TiExportOutline } from "react-icons/ti";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchUsers } from "../../../redux/actions/userActions";
import Loading from "../../../component/splash/loading/Loading";
import NoResult from "../../../component/splash/no-result/NoResult";

function AdminUsers() {
  const fetchUsers = useFetchUsers();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(null);

  const handleSelectUser = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(id)
        ? prevSelectedUsers.filter((userId) => userId !== id)
        : [...prevSelectedUsers, id]
    );
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers?.length === users?.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users?.map((user) => user.id));
    }
  };

  const handleBulkAction = () => {
    alert(`Performing bulk action on users: ${selectedUsers.join(", ")}`);
  };

  const handleFetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetchUsers(page);

      setCount(response.payload.count);
      const newData = response.payload.results;
      // setUsers(newData);
      setUsers((prevItems) => {
        // Merge previous items and new data
        const mergedItems = [...prevItems, ...newData];

        // Remove duplicates based on 'id'
        const uniqueItems = mergedItems.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );

        return uniqueItems;
      });
    } catch (err) {
      console.error("Error fetching users:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchUsers();
  }, []);

  useEffect(() => {
    handleFetchUsers();
  }, [page]);

  const observer = useRef(null);
  const lastItemRef = useRef(null);

  const observeLastItem = useCallback(
    (node) => {
      if (loading) return; // Avoid observing while loading

      // Disconnect the previous observer if it exists
      if (observer.current) observer.current.disconnect();

      // Create a new IntersectionObserver
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);

          setHasMore(users.length < count);
        }
      });

      // Start observing the new node
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, setPage]
  );

  useEffect(() => {
    const currentRef = lastItemRef.current;

    // Observe the last item on mount
    if (currentRef) {
      observeLastItem(currentRef);
    }

    // Cleanup observer on unmount
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [observeLastItem]);

  return (
    <div className="ad__novel">
      <section className="ad__novel__sc__one">
        <h1>All Users</h1>
      </section>
      <section className="ad__novel__sc__two">
        <button className="ad__novel__sc__two__button">
          <TiExportOutline /> Export
        </button>
        <button
          onClick={handleBulkAction}
          disabled={selectedUsers?.length === 0}
        >
          Bulk action
        </button>
      </section>
      <section className="ad__novel__sc__three">
        {loading ? (
          <Loading />
        ) : users?.length == 0 ? (
          <NoResult />
        ) : (
          <div className="admin-table">
            <div className="admin-table-header">
              <div className="admin-table-cell">
                <input
                  type="checkbox"
                  checked={selectedUsers?.length === users?.length}
                  onChange={handleSelectAllUsers}
                />
              </div>
              <div className="admin-table-cell">S/N</div>
              <div className="admin-table-cell">Name</div>
              <div className="admin-table-cell">Pen Name</div>
              <div className="admin-table-cell">Email Address</div>
              <div className="admin-table-cell">Subscribed</div>
              <div className="admin-table-cell">Sub Validity</div>
              <div className="admin-table-cell">Status</div>
              {/* <div className="admin-table-cell">Action</div> */}
            </div>

            <div className="admin-table-body">
              {users?.map((user, index) => {
                if (users.length == index + 1) {
                  return (
                    <div
                      key={user.id}
                      ref={lastItemRef}
                      className="admin-table-row"
                      onClick={() => navigate(`/users/${user.id}`)}
                    >
                      <div className="admin-table-cell">
                        <input
                          type="checkbox"
                          checked={selectedUsers.includes(user.id)}
                          onChange={() => handleSelectUser(user.id)}
                        />
                      </div>
                      <div className="admin-table-cell">{user.id}</div>
                      <div className="admin-table-cell">{`${user.first_name} ${user.last_name}`}</div>
                      <div className="admin-table-cell">{user.pen_name}</div>
                      <div className="admin-table-cell">{user.email}</div>
                      <div className="admin-table-cell">
                        {user.subscription_class}
                      </div>
                      <div className="admin-table-cell">
                        {user.subscription_end_date}
                      </div>
                      <div className="admin-table-cell">
                        {user.is_active ? `active` : `inactive`}
                      </div>
                      {/* <div className="admin-table-cell">{user.action}</div> */}
                    </div>
                  );
                }

                return;
                <div
                  key={user.id}
                  className="admin-table-row"
                  onClick={() => navigate(`/users/${user.id}`)}
                >
                  <div className="admin-table-cell">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                  </div>
                  <div className="admin-table-cell">{user.id}</div>
                  <div className="admin-table-cell">{`${user.first_name} ${user.last_name}`}</div>
                  <div className="admin-table-cell">{user.pen_name}</div>
                  <div className="admin-table-cell">{user.email}</div>
                  <div className="admin-table-cell">
                    {user.subscription_class}
                  </div>
                  <div className="admin-table-cell">
                    {user.subscription_end_date}
                  </div>
                  <div className="admin-table-cell">
                    {user.is_active ? `active` : `inactive`}
                  </div>
                  {/* <div className="admin-table-cell">{user.action}</div> */}
                </div>;
              })}
            </div>
          </div>
        )}

        {/* Loading indicator */}
        {loading && (
          <div className="loading-indicator">
            <Loading />
          </div>
        )}
      </section>
    </div>
  );
}
export default AdminUsers;
