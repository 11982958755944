import "./not-found.css";

import { useNavigate } from "react-router-dom";
import image from "../../../assets/images/rb_5553.png";

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="not__found">
      <img src={image} alt="" />
      <h1>Page not found</h1>
      <p>Sorry, but the page you are looking for could not be found.</p>
      <p>
        {" "}
        You can{" "}
        <span className="not__found__link" onClick={() => navigate("/")}>
          return to our home page
        </span>{" "}
        or
        <span className="not__found__link"> drop us a line</span> if you can't
        find what you are looking for
      </p>
    </div>
  );
}

export default NotFound;
